import React from "react";
import videojs from "video.js";
import 'videojs-vr';
import "video.js/dist/video-js.css";

const VrPlayer = ({src, poster, sourceList, onReady, disablePlay, lessons}) => {
  const playerRef = React.useRef(null);
  let type = "video/mp4";
  // if (src && src.includes('mpd')) {
  //   type = "application/dash+xml";
  // }
  // if (src && src.includes('m3u8')) {
  //   type = "application/x-mpegURL"
  // }
  if (lessons) {
    src = lessons[0].playerVideosrc
  }
  let videoJsOptions = { // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    poster,
    fluid: true,
    sources: sourceList ? sourceList : [{src, type}]
  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });

    player.on('ended', () => {
      console.log('play next');
      let currentIdx = 0
      lessons.map((value, index) => {
        if (value.playerVideosrc == player.currentSrc()) {
          currentIdx = index
        }
      })
      if (lessons[currentIdx + 1]) {
        player.src({
          type: 'video/mp4',
          src: lessons[currentIdx + 1].playerVideosrc
        })
        player.play()
      }
    })

    onReady && onReady(player);
  };
  return (<VideoJS options={videoJsOptions} onReady={handlePlayerReady} disablePlay={disablePlay}/>);
};
const VideoJS = ({options, onReady, disablePlay}) => {

  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      const player = playerRef.current = videojs(videoElement, options, () => {
        player.mediainfo = player.mediainfo || {};
        // player.mediainfo.projection = '360';
        player.vr({
          // projection: 'AUTO',
          projection: '360',
          debug: false,
          forceCardboard: false,
          disableTogglePlay: disablePlay ? disablePlay : false
        });

        // don't allow user change progress
        // player.controlBar.progressControl.disable();

        onReady && onReady(player);
      });
    }
  }, [onReady, options]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef && playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div className="video-container ion-margin-vertical" data-vjs-player>
      <video crossOrigin="anonymous" ref={videoRef} className="video-js vjs-big-play-centered"/>
    </div>
  );
}

export default VrPlayer;
