import Table from "../../../../components/Table";
import {useEffect, useState} from "react";

import io from "socket.io-client"
const socket = io(process.env.REACT_APP_API_URL)

const Viewers = ({classroom}) => {
  const [viewers, setViewers] = useState([]);

  const getUsers = () => {
    socket.emit('Raf', {
      "command": "findConnectors",
      "classID": classroom.ID.toString()
    }).once('Raf', function (data) {
      if (data) {
        setViewers(data)
      }
    })

  }

  useEffect(() => {
    getUsers()
    //update users list every 2sec
    setInterval(() => getUsers(), 2000)

  }, []);

  /*const columns = [
    {
      Header: 'Device',
      accessor: 'name',
    },
    {
      Header: 'Device id',
      accessor: 'id',
    }
  ];*/

  return (
    <div className="connected-viewers">
      <h5>Connected devices ({viewers.length})</h5>
      {/*<Table columns={columns} data={viewers}/>*/}
    </div>
  )
}

export default Viewers
