import {Toast, ToastContainer} from "react-bootstrap";
import {useEffect, useState} from "react";

const ErrorToast = () => {
  const [toasts, setToasts] = useState([]);

  const addToast = (msg) => {
    console.log('add')
    let newToasts = [];
    newToasts.push({
      "id": toasts.length + 1,
      "message": msg
    })
    setToasts(newToasts)
  }

  const removeToast = (id) => {
    let newToasts = [];
    toasts.map((toast, idx) => {
      if (toast.id !== id) {
        newToasts.push(toast)
      }
    })
    setToasts(newToasts)
  }

  useEffect(() => {
    window.addEventListener("unhandledrejection", (event) => {
      if (event.reason.response && event.reason.response.statusText) {
        addToast(event.reason.response.statusText)
      } else {
        addToast(event.reason.message)
      }
    });

    window.addEventListener("error", (event) => {
      addToast(event.message)
    });
  }, []);

  return (
    <ToastContainer>
      {toasts.map((toast) => {
        return (
          <Toast
            key={toast.id}
            autohide={true}
            delay={5000}
            onClose={() => {
              removeToast(toast.id)
            }}
          >
            <Toast.Body>{toast.message}</Toast.Body>
          </Toast>
        );
      })}
    </ToastContainer>
  );
}

export default ErrorToast
