import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Form} from "react-bootstrap";
import "./library.scss";
import io from "socket.io-client"
import ThumbModal from "../../components/video/ThumbModal";
import ClassroomAdd from "./ClassroomAdd";
const socket = io(process.env.REACT_APP_API_URL)

const Library = () => {
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    socket.emit('Raf', {
      "command": "getVideosInfo"
    }).once('Raf', function (data) {
      if (data) {
        const lessons = [];
        console.log(data)

        data.videodata.map((value, index) => {
          // skip thumb directory
          if (value == 'thumb') {
            return;
          }
          lessons.push({
              "id": index,
              "title": value.NameFront,
              "description": value.Category,
              "filename": value.Name,
              "duration": value.Duration,
              "thumb": 'https://api.metaenga.com/static/thumb/' + value.Name + '_Snapshot.jpg',
              "videosrc": 'https://api.metaenga.com/static/' + value.Name + '.mp4'
            },
          )
        });
        setLessons(lessons);
      }
    })
  }, []);

  const DropdownCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a href="#" ref={ref} onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    ><strong>{children}</strong></a>
  ));

  return (
    <>
      <div className="page-title">
        <Button variant="secondary" className="btn-lg">{"<"}</Button>
        <h1>Library</h1>
        {/*<Button variant="primary" className="btn-lg upload">Upload</Button>*/}
      </div>

      <div className="library">
        {/*<div className="filters-toolbar">
          <div className="search-input">
            <Form.Control size="sm" type="text" placeholder="Search..." className="search"/>
          </div>
          <Button size="sm" className="add-filter">Add Filter</Button>
          <Dropdown className="ms-auto">
            <Dropdown.Toggle as={DropdownCustomToggle}>Sort by: Date added</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Date added</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>*/}

        {lessons.map((value) => (
        <div className="library-list-item">
          <ThumbModal data={value}/>
          <div className="item-info">
            <small className="text-truncate">{value.description}</small>
            <h5 className="title text-truncate">{value.title}</h5>
          </div>
          <div className="action ms-auto">
            <ClassroomAdd lesson={value}/>
          </div>
        </div>
        ))}
      </div>
    </>
  )
}

export default Library
