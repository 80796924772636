import React, {FC, useContext, useState} from 'react';
import Axios from "axios";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import {Link} from "react-router-dom";
import {Context} from "../../index";
import metaenga_logo from '../../assets/images/Logo.svg';
import raf_logo from '../../assets/images/RAF-logo.svg';
import io from "socket.io-client"
// @ts-ignore
const socket = io(process.env.REACT_APP_API_URL)

const LoginForm: FC = () => {
  const [email, setEmail] = useState<string>('')
  const [errorEmail, setErrorEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errorPassword, setErrorPassword] = useState<string>('')
  const {store} = useContext(Context);

  // @ts-ignore
  const handleSubmit = (e) => {
    e.preventDefault();
    hideErrors();

    socket.emit('create', 'room1');
    socket.emit('Tfl',
      {
        "command":"Login",
        "email":email,
        "password":password
      })
    socket.once('Tfl',function (data: any) {
      if(data.email){
        // @ts-ignore
        localStorage.setItem('token', data.accessToken);
        localStorage.setItem('user-data', JSON.stringify({
          userSixDigit: data.userSixDigit,
          username: data.username
        }));
        store.setAuth(true);
        //setValidated(true);
      } else if(data.error) {
        if (data.error == 'user_not_found') {
          setErrorEmail('Wrong Email.');
        } else if (data.error == 'wrong_pass') {
          setErrorPassword('Wrong Password.');
        } else {
          setErrorPassword(data.error);
        }
      }
    })

    /* Axios.post(process.env.REACT_APP_API_URL + "/auth/login", {
       email: email,
       password: password
     }).then((response) => {
       if (response.status === 200) {
         // @ts-ignore
         localStorage.setItem('token', response.data.accessToken);
         localStorage.setItem('user-data', JSON.stringify({
           // @ts-ignore
           userSixDigit: response.data.userSixDigit,
           // @ts-ignore
           username: response.data.username,
           // @ts-ignore
           role: response.data.role
         }));
         store.setAuth(true);
         // setValidated(true);
       } else if (response.status === 204) {
         setErrorPassword('Wrong Password.');
       } else if (response.status === 206) {
         setErrorEmail('Wrong Email.');
       }
     })

 */
  }
  const hideErrors = () => {
    setErrorPassword('');
    setErrorEmail('');
  }

  return (
    <>
      <div className="middle">
        <Container>
          <div className="text-center">
            <div className="mb-5">
              <img src={raf_logo} alt="Royal Air Force"/>
            </div>
            {/*<h1 className="title mb-5">Welcome to Meta&nbsp;ENGA</h1>*/}
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="form-inputs">
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" required
                              onChange={e => {
                                setEmail(e.target.value);
                                hideErrors();
                              }}
                              isInvalid={!!errorEmail}
                              value={email}/>
                <Form.Control.Feedback type="invalid">{errorEmail}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-2" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" required
                              placeholder="Enter your password"
                              onChange={e => {
                                setPassword(e.target.value);
                                hideErrors();
                              }}
                              isInvalid={!!errorPassword}
                              value={password}/>
                <Form.Control.Feedback type="invalid">Wrong Password.</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="text-center">
              <div className="mb-5">
                <a href="#">Forgot your password?</a>
              </div>
              <div className="mb-4">
                <Button type="submit">Sign in</Button>
              </div>
            </div>
          </Form>
        </Container>
      </div>
      <Container className="text-center">
        <div className="mb-4">
          {/*<p>Don't have an account? <strong><Link to="/register">Register</Link></strong></p>*/}
        </div>
        <div className="by">
          Powered by <img src={metaenga_logo} width="100" height="20" alt="MetaENGA"/>
        </div>
      </Container>
    </>
  );
};

export default LoginForm;
