import React from "react";
import {Redirect} from "react-router-dom";

// Route Views
import Classrooms from "./views/classrooms/Classrooms";
import Classroom from "./views/classrooms/classroom/Classroom";
import Account from "./views/account/Account";
import Library from "./views/library/Library";

export default [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/library"/>
  },
  {
    path: "/library",
    exact: true,
    component: Library
  },
  {
    path: "/classrooms",
    exact: true,
    component: Classrooms
  },
  {
    path: "/classrooms/:classRoomName",
    exact: true,
    component: Classroom
  },
  {
    path: "/settings",
    exact: true,
    component: Account
  }
];
