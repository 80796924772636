import React, {useEffect} from 'react'
import BTable from 'react-bootstrap/Table';
import {useTable, usePagination, useRowSelect} from 'react-table'

function Table({columns, data}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 999
      }
    },
    usePagination,
    useRowSelect
  )

  return (
    <>
      <BTable striped {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()} className={cell.column.id}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </BTable>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      {/*<div className="pagination">*/}
      {/*  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>*/}
      {/*    {'<<'}*/}
      {/*  </button>*/}
      {/*  {' '}*/}
      {/*  <button onClick={() => previousPage()} disabled={!canPreviousPage}>*/}
      {/*    {'<'}*/}
      {/*  </button>*/}
      {/*  {' '}*/}
      {/*  <button onClick={() => nextPage()} disabled={!canNextPage}>*/}
      {/*    {'>'}*/}
      {/*  </button>*/}
      {/*  {' '}*/}
      {/*  <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>*/}
      {/*    {'>>'}*/}
      {/*  </button>*/}
      {/*  {' '}*/}
      {/*  <span>*/}
      {/*    Page{' '}*/}
      {/*    <strong>*/}
      {/*      {pageIndex + 1} of {pageOptions.length}*/}
      {/*    </strong>{' '}*/}
      {/*  </span>*/}
      {/*  <span>*/}
      {/*    | Go to page:{' '}*/}
      {/*    <input*/}
      {/*      type="number"*/}
      {/*      defaultValue={pageIndex + 1}*/}
      {/*      onChange={e => {*/}
      {/*        const page = e.target.value ? Number(e.target.value) - 1 : 0*/}
      {/*        gotoPage(page)*/}
      {/*      }}*/}
      {/*      style={{width: '100px'}}*/}
      {/*    />*/}
      {/*  </span>{' '}*/}
      {/*  <select*/}
      {/*    value={pageSize}*/}
      {/*    onChange={e => {*/}
      {/*      setPageSize(Number(e.target.value))*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {[2, 10, 20].map(pageSize => (*/}
      {/*      <option key={pageSize} value={pageSize}>*/}
      {/*        Show {pageSize}*/}
      {/*      </option>*/}
      {/*    ))}*/}
      {/*  </select>*/}
      {/*</div>*/}
      {/*<pre>*/}
      {/*  <code>*/}
      {/*    {JSON.stringify(*/}
      {/*      {*/}
      {/*        pageIndex,*/}
      {/*        pageSize,*/}
      {/*        pageCount,*/}
      {/*        canNextPage,*/}
      {/*        canPreviousPage,*/}
      {/*      },*/}
      {/*      null,*/}
      {/*      2*/}
      {/*    )}*/}
      {/*  </code>*/}
      {/*</pre>*/}
    </>
  )
}

export default Table
