import React, {FC, useContext, useState} from 'react';
import Axios from "axios";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import {Link} from "react-router-dom";
import {Context} from "../../index";

const RegisterForm: FC = () => {
  const [email, setEmail] = useState<string>('')
  const [errorEmail, setErrorEmail] = useState<string>('')
  const [log, setLog] = useState<string>('')
  const [errorLog, setErrorLog] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errorPassword, setErrorPassword] = useState<string>('')

  const {store} = useContext(Context);
  const [validated, setValidated] = useState(false);

  // @ts-ignore
  const handleSubmit = (e) => {
    e.preventDefault();

    // setErrorEmail('');
    // setErrorLog('');
    // setErrorPassword('');

    if (!email) {
      setErrorEmail('This field is required.');
    } else if (!log) {
      setErrorLog('This field is required.');
    } else if (!password) {
      setErrorPassword('This field is required.');
    }

    if (email && log && password) {
      Axios.post(process.env.REACT_APP_API_URL + "/auth/registration", {
        log: log,
        email: email,
        password: password
      }).then((response) => {
        console.error(response)
        if (response.status === 200) {
          // @ts-ignore
          localStorage.setItem('token', response.data.accessToken);
          localStorage.setItem('user-data', JSON.stringify({
            // @ts-ignore
            userSixDigit: response.data.user.randomstring,
            // @ts-ignore
            username: response.data.user.log,
            // @ts-ignore
            role: response.data.user.role
          }));
          store.setAuth(true);
          window.location.href = '/'
        } else {
          console.log(response)
        }
      }).catch((e) => {
        console.log(e.response);
        // @ts-ignore
        e.response.data.errors.map((value, index) => {
          if (value.param == 'password') {
            setErrorPassword(value.msg);
          } else if (value.param == 'email') {
            setErrorEmail(value.msg)
          } else if (value.param == 'log') {
            setErrorLog(value.msg);
          }
        })
      })
    }
  }

  return (
    <>
      <div className="middle">
        <Container>
          <div className="text-center">
            <h1 className="title mb-5">Register</h1>
          </div>
          <Form onSubmit={handleSubmit} validated={validated}>
            <div className="form-inputs">
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email"
                              onChange={e => {
                                setEmail(e.target.value);
                                setErrorEmail('');
                              }}
                              isInvalid={!!errorEmail}
                              value={email}/>
                <Form.Control.Feedback type="invalid">{errorEmail}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="log">
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name"
                              onChange={e => {
                                setLog(e.target.value);
                                setErrorLog('');
                              }}
                              isInvalid={!!errorLog}
                              value={log}/>
                <Form.Control.Feedback type="invalid">{errorLog}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password"
                              placeholder="Enter your password"
                              onChange={e => {
                                setPassword(e.target.value);
                                setErrorPassword('');
                              }}
                              isInvalid={!!errorPassword}
                              value={password}/>
                <Form.Control.Feedback type="invalid">{errorPassword}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="text-center">
              <div className="mb-4">
                <Button type="submit">Register</Button>
              </div>
            </div>
          </Form>
        </Container>
      </div>
      <Container className="text-center">
        <p>Already have an account? <strong><Link to="/">Login</Link></strong></p>
      </Container>
    </>
  );
};

export default RegisterForm;
