import React, {useEffect, useState} from "react";
import {Col, Row, Form, Button, Modal} from "react-bootstrap";
import LessonsListDraggable from "../../../components/lessons/LessonsListDraggable/LessonsListDraggable";
import AddLessons from "../../../components/lessons/AddLessons";
import io from "socket.io-client"
const socket = io(process.env.REACT_APP_API_URL)


const ClassroomAdd = () => {
  const [show, setShow] = useState(false);
  const [lessons, setLessons] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // useEffect(() => {
  // }, []);

  const [name, setName] = useState('');
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      var adminMail = JSON.parse(localStorage.getItem('user-data'))
      socket.emit('Raf', {
        "command": "newClassRoom",
        "classRoomName": name,
        "randomstring": adminMail.userSixDigit
      }).once('Raf', function (data) {
        if (data.classRoom) {
          // add videos to classroom
          if (lessons.length > 0) {
            // add videos synchronously and reload page after adding all
            const addVideo = (index) => {
              console.log(index)
              if (lessons[index]) {
                socket.emit('Raf', {
                  "command": "addVideo",
                  "videoname": lessons[index].filename + '.mp4',
                  "classRoomName": name,
                  "randomstring": adminMail.userSixDigit
                }).once('Raf', function (data) {
                  if (data) {
                    addVideo(index + 1)
                  }
                })
              } else {
                window.location.reload();
              }
            }

            addVideo(0)
          } else {
            window.location.reload();
          }
        } else {
          alert(data)
        }
      })
    }

    setValidated(true);
  };

  const onLessonAddHandle = (selected) => {
    const newLessons = [...lessons, ...selected];
    setLessons(newLessons)
  }

  const onLessonDeleteHandle = (id) => {
    const newLessons = [];
    lessons.map((value) => {
      if (value.filename !== id) {
        newLessons.push(value)
      }
    })
    setLessons(newLessons)
  }

  const onSortHandle = (items) => {
    setLessons(items)
  }

  return (
    <>
      <Button variant="primary" className="btn-lg classroom-add" onClick={handleShow}>Create Classroom</Button>
      <Modal centered size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Configure Classroom</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} validated={validated} noValidate id="classroom-add-form">
            <Form.Group className="mb-3" controlId="classroom-id">
              <Form.Label>Classroom name</Form.Label>
              <Form.Control type="text" placeholder="Classroom 01 by John Dilinger"
                            required
                            onChange={e => {
                              setName(e.target.value);
                            }}/>
              <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="content">
              <Form.Label>Content</Form.Label>
              <LessonsListDraggable data={lessons} onLessonDelete={onLessonDeleteHandle} onSort={onSortHandle}/>
              <div className="add-to-playlist text-center mb-5">
                <AddLessons onAdd={onLessonAddHandle}/>
              </div>
            </Form.Group>
            {/*<Form.Group as={Row} controlId="available-for">
              <Form.Label column sm={3}>Available for</Form.Label>
              <Col sm={9}>
                <Form.Select disabled>
                  <option>All</option>
                </Form.Select>
              </Col>
            </Form.Group>*/}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="lg" type="submit" form="classroom-add-form">Create</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ClassroomAdd
