import {Breadcrumb, Button, Dropdown, Form} from "react-bootstrap";
import ClassroomAdd from "./classroom/ClassroomAdd";
import "./classrooms.scss"
import React, {useEffect, useState} from "react";
import TableCheckboxed from "../../components/TableCheckboxed";
import {format} from 'date-fns'
import io from "socket.io-client"
const socket = io(process.env.REACT_APP_API_URL)

const Classrooms = () => {
  const columns = [
    {
      Header: 'Classroom id',
      accessor: 'name',
      Cell: ({row}) => (
        <a href={"/classrooms/" + row.original.name} className="text-reset text-decoration-none">{row.original.name}</a>
      )
    },
    /*{
      Header: 'Curator',
      accessor: 'curator',
    },
    {
      Header: 'Connected Users',
      accessor: 'users',
      Cell: ({row}) => (
        <div className="users">
          {row.original.users.slice(0, 3).map(column => (
            // <img src={column}/>
            <div className="in">
              {column.userName && <div>{column.userName.substring(0, 1)} {column.userName.substring(1, 2)}</div>}
            </div>
          ))}
          {row.original.users.length - 3 > 0 &&
          <div className="count">{row.original.users.length - 3}</div>
          }
        </div>
      )
    },*/
    {
      Header: 'Date Created',
      accessor: 'date',
    },
    {
      accessor: 'id',
      Cell: ({row}) => {
        return (
          <Dropdown className="single-action">
            <Dropdown.Toggle>Actions</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {handleDelete(row.original.name)}}>Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )
      }
    }
  ];

  const [data, setData] = useState([]);

  const handleDelete = (id) => {
    var adminMail = JSON.parse(localStorage.getItem('user-data'))
    socket.emit('Raf', {
      "command": "shutOffClassRoom",
      "classRoomName": id,
      "randomstring": adminMail.userSixDigit
    }).once('Raf', function (data) {
      window.location.reload();
    })
  }

  useEffect(() => {
    var adminMail = JSON.parse(localStorage.getItem('user-data'))
    socket.emit('Raf', {
      "command": "getClassRoomInfo",
      "randomstring": adminMail.userSixDigit
    }).once('Raf', function (data) {
      if (data) {
        const classrooms = [];
        data.map((value, index) => {
          classrooms.push({
              "id": value._id,
              "name": value.classRoomName,
              // "curator": "-",
              // "curator": value.manager,
              // "users": value.users,
              "date": value.creationTime ? format(new Date(value.creationTime), 'd-M-y') : "-"
            },
          )
        });
        setData(classrooms);
      }
    })
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Classrooms</Breadcrumb.Item>
      </Breadcrumb>
      <div className="page-title">
        <Button variant="secondary" className="btn-lg">{"<"}</Button>
        <h1>Classrooms</h1>
        {/*{JSON.parse(localStorage.getItem('user-data')) && JSON.parse(localStorage.getItem('user-data')).role == 'ADMIN' &&*/}
        <ClassroomAdd/>
        {/*}*/}
      </div>
      {/*<div className="filters-toolbar mb-2">
        <div className="search-input">
          <Form.Control size="sm" type="text" placeholder="Search..." className="search"/>
        </div>
        <Button size="sm" className="add-filter">Add Filter</Button>
        <div className="filter-current">
          <span className="filter-label">Curator :</span>
          <span className="filter-value">Andressa Feraz</span>
          <a href="#" className="remove">Remove</a>
        </div>
        <div className="filter-current">
          <span className="filter-label">Users :</span>
          <span className="filter-value">Over 10</span>
          <a href="#" className="remove">Remove</a>
        </div>
      </div>*/}
      <div className="classroom-listing">
        <TableCheckboxed columns={columns} data={data}/>
      </div>
    </>
  )
}

export default Classrooms
