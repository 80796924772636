import React, {useEffect, useRef, useState} from "react";
import {Form, Button, Modal} from "react-bootstrap";
import io from "socket.io-client"
import ThumbModal from "../video/ThumbModal";

const socket = io(process.env.REACT_APP_API_URL)

const AddLessons = ({classRoomName, onAdd}) => {
  const [show, setShow] = useState(false);
  const [lessons, setLessons] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  }

  useEffect(() => {
    socket.emit('Raf', {"command": "getVideosInfo"})
    socket.once('Raf', function (data) {
      if (data) {
        const lessons = [];

        data.videodata.map((value, index) => {
          // skip thumb directory
          if (value === 'thumb') {
            return;
          }
          lessons.push({
              "id": index,
              "title": value.NameFront,
              "description": value.Category,
              "filename": value.Name,
              "duration": value.Duration,
              "thumb": 'https://api.metaenga.com/static/thumb/' + value.Name + '_Snapshot.jpg',
              "videosrc": 'https://api.metaenga.com/static/' + value.Name + '.mp4'
            },
          )
        });

        setLessons(lessons);
      }
    })

  }, []);

  const [selected, setSelected] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (selected.length) {
      if (classRoomName) {
        const adminMail = JSON.parse(localStorage.getItem('user-data'))
        // add videos synchronously
        const addVideo = (index) => {
          if (selected[index]) {
            socket.emit('Raf', {
              "command": "addVideo",
              "videoname": selected[index] + '.mp4',
              "classRoomName": classRoomName,
              "randomstring": adminMail.userSixDigit
            }).once('Raf', function (data) {
              if (data) {
                addVideo(index + 1)
              }
            })
          } else {
            onAdd();
            handleClose();
          }
        }

        addVideo(0)

      } else {
        let selectedFull = [];
        selected.map(value => {
          lessons.map(lesson => {
            if (lesson.filename === value) {
              selectedFull.push(lesson)
            }
          })
        })
        onAdd(selectedFull);
        handleClose();
      }
      //reset selected value to prevent duplicates on adding next time
      setSelected([]);
    }
  };

  return (
    <>
      <a href="#" onClick={handleShow}><strong>Add lesson from library</strong></a>
      <Modal centered size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add lessons from library</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="classroom-id">
            <Form.Label>Select lessons</Form.Label>
            {/*<div className="search-input">
              <Form.Control type="text" placeholder="Search..." disabled/>
            </div>*/}
          </Form.Group>
          {lessons.map((value) => (
            <div className="lessons-list-draggable-item">
              <ThumbModal data={value}/>
              <div className="item-info">
                <small className="text-truncate">{value.description}</small>
                <h5 className="title text-truncate">{value.title}</h5>
              </div>
              <div className="action ms-auto">
                <input
                  type="checkbox"
                  value={value.filename}
                  onChange={e => {
                    if (e.target.checked) {
                      setSelected([...selected, e.target.value]);
                    } else {
                      let check_list = [];
                      selected.map(check => {
                        if (check != e.target.value) {
                          check_list.push(check);
                        }
                      });
                      setSelected(check_list);
                    }
                  }}
                />
              </div>
            </div>
          ))}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="lg" onClick={handleSubmit}>Add selected</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddLessons

