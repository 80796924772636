import React from 'react';
import {Button} from "react-bootstrap";

const Account = () => {
  return (
    <>
      <div className="page-title">
        <Button variant="secondary" className="btn-lg">{"<"}</Button>
        <h1>Settings</h1>
      </div>
      {/*<p>Your sync code is: {JSON.parse(localStorage.getItem('user-data')).userSixDigit}</p>*/}
      <p>Username: {JSON.parse(localStorage.getItem('user-data')).username}</p>
    </>
  )
}

export default Account
