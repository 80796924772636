import React, {useEffect, useRef, useState} from "react";
import LessonsListDraggable from "../../../../components/lessons/LessonsListDraggable/LessonsListDraggable";
import "./player.scss"
import VrPlayer from "./VrPlayer";
import AddLessons from "../../../../components/lessons/AddLessons";
import io from "socket.io-client"
const socket = io(process.env.REACT_APP_API_URL)

const Player = ({classroom, onPlayerReady}) => {
  const [lessons, setLessons] = useState([]);
  const [sortDisabled, setSortDisabled] = useState(false);
  // const [videosrc, setVideosrc] = useState(null);
  // const playerRef = useRef(null);
  const adminMail = JSON.parse(localStorage.getItem('user-data'))

  useEffect(() => {
    if (classroom.classRoomVideo) {
      // socket.emit('Raf', {
      //   "command": "execVideo",
      //   "videoname": classroom.classRoomVideo[0],
      //   "classRoomName": classroom.classRoomName,
      //   "randomstring": adminMail.userSixDigit
      // }).once('Raf', function (data) {
      //   if (data) {
      //     console.log(data)
      //     // if no video - there will be object
      //     if (typeof data === 'string') {
      //       setVideosrc(data)
      //     }
      //   }
      // })
    }

    prepareLessons(classroom);
  }, [])

  const prepareLessons = (classroom) => {
    let tmp = [];
    const prepareLesson = (index) => {
      if (classroom.classRoomVideo[index]) {
        const videoname = String(classroom.classRoomVideo[index].split('.').slice(0, -1).join('.'));
        socket.emit('videoRaf', {
          "command": "getOneVideoInfo",
          "video": videoname
        }).once('videoRaf', function (videoData) {
          const itemVideosrc = 'https://api.metaenga.com/static/' + videoData.Name + '.mp4'
          tmp.push({
            "id": videoData.videoid,
            "title": videoData.NameFront,
            "description": videoData.Category,
            "filename": videoData.Name,
            "duration": videoData.Duration,
            "thumb": 'https://api.metaenga.com/static/thumb/' + videoData.Name + '_Snapshot.jpg',
            // "videosrc": itemVideosrc
            "playerVideosrc": itemVideosrc,
            "isCurrent": false
          })

          prepareLesson(index + 1)
        })
      } else {
        setLessons(tmp)
      }
    }

    prepareLesson(0)
  }

  const handlePlay = () => {
    // playerRef.current.paused() ? playerRef.current.play() : playerRef.current.pause()
  };

  const handlePlayerReady = (player) => {
    // playerRef.current = player;

    player.on('play', () => {
      setSortDisabled(true)

      let tmp = []
      lessons.map((value) => {
        if (value.playerVideosrc == player.currentSrc()) {
          value.isActive = true
        } else {
          value.isActive = false
        }
        tmp.push(value)
      })
      setLessons(tmp)
    })

    onPlayerReady(player)
  };

  const lessonsRefresh = () => {
    socket.emit('Raf', {
      "command": "getOneClassRoomInfo",
      "classRoomName": classroom.classRoomName,
      "randomstring": adminMail.userSixDigit
    }).once('Raf', function (data) {
      if (data) {
        prepareLessons(data);
        console.log(data)
      }
    })
    // window.location.reload();
  }

  const onLessonsSort = (items, oldIndex, newIndex) => {
    socket.emit('Raf', {
      "command": "moveVideo",
      "classRoomName": classroom.classRoomName,
      "randomstring": adminMail.userSixDigit,
      "videoname": items[newIndex].filename + '.mp4',
      "whereToMove": newIndex.toString()
    }).once('Raf', function (data) {
      console.log(data)
      lessonsRefresh()
    })
  }

  return (
    <div className="classroom-viewer">
      <div className="video-container">
        {lessons.length > 0 && <VrPlayer lessons={lessons} onReady={handlePlayerReady} disablePlay={true}/>}
      </div>
      <div className="playlist">
        {classroom.classRoomName && <LessonsListDraggable data={lessons} classRoomName={classroom.classRoomName} onLessonDelete={lessonsRefresh} onSort={onLessonsSort} sortDisabled={sortDisabled}/>}
        {sortDisabled == false &&
        <div className="add-to-playlist text-center mb-3">
          {classroom.classRoomName && <AddLessons classRoomName={classroom.classRoomName} onAdd={lessonsRefresh}/>}
        </div>
        }
      </div>
    </div>
  )
}

export default Player

/*

mediasource blob example

<video id="video"></video>
    <script>
        // As before, I'm regularly grabbing blobs of video data
        // The implementation of "nextChunk" could be various things:
        //   - reading from a MediaRecorder
        //   - reading from an XMLHttpRequest
        //   - reading from a local webcam
        //   - generating the files on the fly in JavaScript
        //   - etc
        var arrayOfBlobs = [];
        setInterval(function() {
            arrayOfBlobs.append(nextChunk());
            // NEW: Try to flush our queue of video data to the video element
            appendToSourceBuffer();
        }, 1000);

        // 1. Create a `MediaSource`
        var mediaSource = new MediaSource();

        // 2. Create an object URL from the `MediaSource`
        var url = URL.createObjectURL(mediaSource);

        // 3. Set the video's `src` to the object URL
        var video = document.getElementById("video");
        video.src = url;

        // 4. On the `sourceopen` event, create a `SourceBuffer`
        var sourceBuffer = null;
        mediaSource.addEventListener("sourceopen", function()
        {
            // NOTE: Browsers are VERY picky about the codec being EXACTLY
            // right here. Make sure you know which codecs you're using!
            sourceBuffer = mediaSource.addSourceBuffer("video/webm; codecs=""opus,vp8""");

            // If we requested any video data prior to setting up the SourceBuffer,
            // we want to make sure we only append one blob at a time
            sourceBuffer.addEventListener("updateend", appendToSourceBuffer);
        });

        // 5. Use `SourceBuffer.appendBuffer()` to add all of your chunks to the video
        function appendToSourceBuffer()
        {
            if (
                mediaSource.readyState === "open" &&
                sourceBuffer &&
                sourceBuffer.updating === false
            )
            {
                sourceBuffer.appendBuffer(arrayOfBlobs.shift());
            }

            // Limit the total buffer size to 20 minutes
            // This way we don't run out of RAM
            if (
                video.buffered.length &&
                video.buffered.end(0) - video.buffered.start(0) > 1200
            )
            {
                sourceBuffer.remove(0, video.buffered.end(0) - 1200)
            }
        }
    </script>

 */
