import React, {useEffect, useRef, useState} from 'react';
import {Breadcrumb, Button} from "react-bootstrap";
import Player from "./player/Player";
import "./classroom.scss";
import Viewers from "./viewers/Viewers";
import io from "socket.io-client"

const socket = io(process.env.REACT_APP_API_URL)


const Classroom = (props) => {
  const [classroom, setClassroom] = useState({});
  const [playerState, setPlayerState] = useState(null);
  const playerRef = useRef(null);

  useEffect(() => {
    var adminMail = JSON.parse(localStorage.getItem('user-data'))
    socket.emit('Raf', {
      "command": "getOneClassRoomInfo",
      "classRoomName": props.match.params.classRoomName,
      "randomstring": adminMail.userSixDigit
    }).once('Raf', function (data) {
      if (data) {
        setClassroom(data);

        setInterval(() => {
          if (data.ID) {
            socket.emit('Raf', {
              command: 'ping',
              classID: data.ID.toString()
            })
          }
        }, 5000)
      }
    })
  }, []);

  const onPlayerReady = (player) => {
    player.off('click')

    setPlayerState('ready')
    playerRef.current = player
  }

  const handleStart = () => {
    sendStart()
    setPlayerState('play')
    playerRef.current.play()
  }

  const handlePause = () => {
    var adminMail = JSON.parse(localStorage.getItem('user-data'))
    socket.emit('Raf', {
      "command": "setPause",
      "classRoomName": classroom.classRoomName,
      "randomstring": adminMail.userSixDigit
    }).once('Raf', function (data) {
      if (data) {
        console.log(data)
      }
    })
    setPlayerState('pause')
    playerRef.current.pause()
  }

  const handleResume = () => {
    sendStart()
    setPlayerState('play')
    playerRef.current.play()
  }

  const sendStart = () => {
    const adminMail = JSON.parse(localStorage.getItem('user-data'))
    socket.emit('Raf', {
      "command": "setStart",
      "classRoomName": classroom.classRoomName,
      "randomstring": adminMail.userSixDigit
    }).once('Raf', function (data) {
      if (data) {
        console.log(data)
      }
    })
  }

  if (classroom.classRoomName) {
    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item href="/classrooms">Classrooms</Breadcrumb.Item>
          <Breadcrumb.Item active>{classroom.classRoomName}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="page-title">
          <Button variant="secondary" href="/classrooms" className="btn-lg">{"<"}</Button>
          <h1>{classroom.classRoomName}</h1>
          {playerState &&
          <>
            {playerState === 'ready' &&
            <Button variant="primary" className="btn-lg start-session" onClick={handleStart}>Start Session</Button>
            }
            {playerState === 'play' &&
            <Button variant="danger" className="btn-lg pause-session" onClick={handlePause}>Pause Session</Button>
            }
            {playerState === 'pause' &&
            <Button variant="primary" className="btn-lg resume-session" onClick={handleResume}>Resume Session</Button>
            }
          </>
          }
        </div>
        <Player classroom={classroom} onPlayerReady={onPlayerReady}/>
        <Viewers classroom={classroom}/>
      </>
    )
  } else {
    return null;
  }
}

export default Classroom
