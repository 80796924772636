import React, {useEffect, useState} from "react";
import {Form, Button, Modal} from "react-bootstrap";
import LessonsListDraggable from "../../components/lessons/LessonsListDraggable/LessonsListDraggable";
import AddLessons from "../../components/lessons/AddLessons";
import io from "socket.io-client"

const socket = io(process.env.REACT_APP_API_URL)

const ClassroomAdd = ({lesson}) => {
  const [show, setShow] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const adminMail = JSON.parse(localStorage.getItem('user-data'))

  useEffect(() => {
    setLessons([lesson])

    socket.emit('Raf', {
      "command": "getClassRoomInfo",
      "randomstring": adminMail.userSixDigit
    }).once('Raf', function (data) {
      if (data) {
        const tmp = [];
        data.map((value) => {
          tmp.push({
              "id": value._id,
              "name": value.classRoomName
            },
          )
        });
        setClassrooms(tmp);
      }
    })
  }, []);

  const [newName, setNewName] = useState('');
  const [name, setName] = useState('');
  const [create, setCreate] = useState('1');
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (create == 1) {
      const form = e.currentTarget;
      if (form.checkValidity() === true) {
        socket.emit('Raf', {
          "command": "newClassRoom",
          "classRoomName": newName,
          "randomstring": adminMail.userSixDigit
        }).once('Raf', function (data) {
          if (data.classRoom) {
            addVideos(newName)
          } else {
            alert(data)
          }
        })
      }
    } else {
      if (name == '') {
        addVideos(classrooms.find(Boolean).name)
      } else {
        addVideos(name)
      }
    }

    // setValidated(true);
  };

  const addVideos = (classRoomName) => {
    // add videos synchronously
    const addVideo = (index) => {
      if (lessons[index]) {
        socket.emit('Raf', {
          "command": "addVideo",
          "videoname": lessons[index].filename + '.mp4',
          "classRoomName": classRoomName,
          "randomstring": adminMail.userSixDigit
        }).once('Raf', function (data) {
          if (data) {
            addVideo(index + 1)
          }
        })
      } else {
        window.location.href = '/classrooms/' + classRoomName;
      }
    }

    addVideo(0)
  }

  const onLessonAddHandle = (selected) => {
    const newLessons = [...lessons, ...selected];
    setLessons(newLessons)
  }

  const onLessonDeleteHandle = (id) => {
    const newLessons = [];
    lessons.map((value) => {
      if (value.filename !== id) {
        newLessons.push(value)
      }
    })
    setLessons(newLessons)
  }

  const onSortHandle = (items) => {
    setLessons(items)
  }

  return (
    <>
      <Button variant="primary" className="btn-add" onClick={handleShow}><span>Add to a classroom</span></Button>
      <Modal centered size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Configure a classroom</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} validated={validated} noValidate id="classroom-add-form">
            <Form.Group className="mb-3" controlId="content">
              <Form.Label>Select lessons</Form.Label>
              <LessonsListDraggable data={lessons} onLessonDelete={onLessonDeleteHandle} onSort={onSortHandle}/>
              <div className="add-to-playlist text-center mb-5">
                <AddLessons onAdd={onLessonAddHandle}/>
              </div>
            </Form.Group>
            <Form.Check className="mb-3" type="radio" name="create" label="Select a classroom"
                        id="select-classroom-radio" value="0" checked={create === '0'}
                        onChange={e => {
                          setCreate(e.target.value);
                        }}
                        />
            <Form.Group className="mb-3" controlId="select-classroom">
              <Form.Select onChange={e => {
                setName(e.target.value)
                setCreate('0')
              }}>
                {classrooms.map((value) => (
                  <option>{value.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <div className="text-center">or</div>
            <Form.Check className="mb-3" type="radio" name="create" label="Create a new classroom"
                        id="create-classroom-radio" value="1" checked={create === '1'}
                        onChange={e => {
                          setCreate(e.target.value);
                        }}/>
            <Form.Group controlId="classroom-id">
              <Form.Control type="text" placeholder="Type a classroom title"
                            required
                            onChange={e => {
                              setNewName(e.target.value);
                            }}/>
              <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="lg" type="submit" form="classroom-add-form">Start</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ClassroomAdd
