import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import Store from "./store/store";

interface State {
  store: Store,
}

export const store = new Store();

export const Context = createContext<State>({
  store,
})

ReactDOM.render(
  <Context.Provider value={{store}}>
    <App/>
  </Context.Provider>,
  document.getElementById('root')
);

