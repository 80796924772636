import React, {useEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import VrPlayer from "../../views/classrooms/classroom/player/VrPlayer";
import "./thumbModal.scss";

const ThumbModal = ({data}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  }

  return (
    <div className="thumb-modal">
      {data.thumb &&
      <img src={data.thumb} alt={data.title}/>
      }
      {data.videosrc &&
        <>
          <div className="play" onClick={handleShow}>Play</div>
          <Modal centered dialogClassName="video-modal" backdropClassName="video-backdrop" show={show} onHide={handleClose}>
            <VrPlayer src={data.videosrc}/>
          </Modal>
        </>
      }
      {data.duration &&
      <div className="duration">{data.duration}</div>
      }
    </div>
  )
}

export default ThumbModal

