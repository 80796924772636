import React, {FC} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './login.scss';

import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

const Login: FC = () => {
  return (
    <div className="login">
      <Router>
        <Switch>
          <Route path="/register" exact={true}>
            <RegisterForm/>
          </Route>
          <Route path="/">
            <LoginForm/>
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default Login;
