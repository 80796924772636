import React, {FC, useContext, useEffect, useState} from 'react';
import Login from '../views/login/Login';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import routes from "../routes";
import '../styles/app.scss';
import AppNavbar from "./layout/Navbar/AppNavbar";
import ErrorToast from "./error/errorToast";

const App: FC = () => {
  const {store} = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      store.checkAuth();
    }
  }, [])

  if (store.isLoading) {
    return <div>Loading...</div>
  }

  // if (!store.isAuth) {
  if (!localStorage.getItem('token') && !store.isAuth) {
    return (
      <><ErrorToast/><Login/></>
    );
  }

  return (
    <Router>
      <div className="default-layout">
        <AppNavbar/>
        <div className="page-body">
          <ErrorToast/>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                // children={<route.component/>}
                component={route.component}
              />
            ))}
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default observer(App);
