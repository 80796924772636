import {IUser} from "../models/IUser";
import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";
import axios from 'axios';
import {AuthResponse} from "../models/response/AuthResponse";
import {API_URL} from "../http";

export default class Store {
  user = {} as IUser;
  isAuth = false;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool: boolean) {
    this.isAuth = bool;
  }

  setUser(user: IUser) {
    this.user = user;
  }

  setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  // async login(email: string, password: string) {
  //   try {
  //     const response = await AuthService.login(email, password);
  //     console.log(response)
  //     // localStorage.setItem('token', response.data.accessToken);
  //     if (response.data.token) {
  //       localStorage.setItem('token', response.data.token);
  //       this.setAuth(true);
  //       this.setUser(response.data.user);
  //     }
  //   } catch (e) {
  //     // console.log(e.response?.data?.message);
  //     console.log(e);
  //   }
  // }

  async registration(email: string, password: string) {
    try {
      const response = await AuthService.registration(email, password);
      console.log(response)
      localStorage.setItem('token', response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
    } catch (e) {
      // console.log(e.response?.data?.message);
      console.log(e);
    }
  }

  // async logout() {
  //   try {
  //     const response = await AuthService.logout();
  //     localStorage.removeItem('token');
  //     this.setAuth(false);
  //     this.setUser({} as IUser);
  //   } catch (e) {
  //     // console.log(e.response?.data?.message);
  //     console.log(e);
  //   }
  // }

  checkAuth() {
  // async checkAuth() {
    // this.setLoading(true);
    // try {
    //   const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials: true})
    //   console.log(response);
    //   localStorage.setItem('token', response.data.accessToken);
    //   this.setAuth(true);
    //   this.setUser(response.data.user);
    // } catch (e) {
    //   // console.log(e.response?.data?.message);
    //   console.log(e);
    // } finally {
    //   this.setLoading(false);
    // }

    /**
     * as refresh isn't implemented at backend yet...
     */
    if (localStorage.getItem('token')) {
      this.setAuth(true);
    }
  }
}
