import React from "react";
import {Navbar, Container, Nav, NavDropdown} from "react-bootstrap";
import {
  Link,
  NavLink
} from "react-router-dom"

import style from "./navbar.scss";

import logo from "../../../assets/images/Logo.svg";
import io from "socket.io-client"
// @ts-ignore
const socket = io(process.env.REACT_APP_API_URL)
const Logout = (e) => {
  e.preventDefault();
  var aMail = JSON.parse(localStorage.getItem('user-data'))
 // socket.emit('Tfl',
 //   {
  //    "command":"Logout",
  //    "sixdigitkey":aMail.userSixDigit

  //  })
  localStorage.removeItem('token');
  localStorage.removeItem('user-data');
  document.location.reload();
}

const AppNavbar = () => {
  return (
    <Navbar bg="light" expand="md">
      <Container fluid>
        <Navbar.Toggle aria-controls="main-navbar-nav"/>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="MetaENGA"/>
          </Link>
        </Navbar.Brand>
      </Container>
      <Navbar.Collapse id="main-navbar-nav">
        <Container>
          <Nav>
            <NavLink to="/library" className="nav-link" exact>Library</NavLink>
            <NavLink to="/classrooms" className="nav-link" exact>Classrooms</NavLink>
            <NavLink to="/settings" className="nav-link" exact>Settings</NavLink>
            <a href="#" className="nav-link" onClick={Logout}>Logout</a>
          </Nav>
        </Container>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default AppNavbar
