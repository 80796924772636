import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import "./lessons-list-draggable.scss"
import {Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import io from "socket.io-client"
import ThumbModal from "../../video/ThumbModal";
const socket = io(process.env.REACT_APP_API_URL)


const LessonsListDraggable = ({data, classRoomName, onLessonDelete, onSort, sortDisabled}) => {
  const SortableItem = SortableElement(({value}) => (
    <li tabIndex={0} className={`lessons-list-draggable-item${value.isActive? " active" : ""}`}>
      <div className="drag-handle"></div>
      <ThumbModal data={value}/>
      <div className="item-info">
        <small className="text-truncate">{value.description}</small>
        <h5 className="title text-truncate">{value.title}</h5>
      </div>
      <div className="action ms-auto">
        {sortDisabled != true &&
        <Button variant="primary" className="btn-delete" onClick={(e) => {
          e.preventDefault();
          deleteHandle(value.filename)
        }}>Delete</Button>
        }
      </div>
    </li>
  ));

  const deleteHandle = (id) => {
    if (classRoomName) {
      var adminMail = JSON.parse(localStorage.getItem('user-data'))
      socket.emit('Raf', {
        "command": "deleteVideo",
        "videoname": id + '.mp4',
        "classRoomName": classRoomName,
        "randomstring": adminMail.userSixDigit
      }).once('Raf', function (data) {
        if (data) {
          if (data.resp === 'video_deleted') {
            onLessonDelete();
          }
        }
      })
    } else {
      onLessonDelete(id);
    }
  }

  const SortableList = SortableContainer(({items, disabled}) => {
    return (
      <ul className="lessons-list-draggable list-unstyled">
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value} disabled={disabled}/>
        ))}
      </ul>
    );
  });

  const arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };

  const arrayMove = (array, from, to) => {
    array = array.slice();
    arrayMoveMutate(array, from, to);
    return array;
  };


  const [items, setItems] = useState(data);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const onSortEnd = ({oldIndex, newIndex}) => {
    const movedItems = arrayMove(items, oldIndex, newIndex);
    setItems(movedItems);
    if (onSort) {
      onSort(movedItems, oldIndex, newIndex);
    }
  };

  return (
    <SortableList items={items} onSortEnd={onSortEnd} distance={10} disabled={sortDisabled}/>
  )
}

export default LessonsListDraggable
